import React from 'react';

const Home = () => {
    return <>
        <div className="home">
            <h1>Welcome</h1>
            <p>A small printable worksheet generator developed to improve my kid math skills. Hope this will help other parents too...</p>
            <p>Its currently a small weekend project, soon planning to add more topics like</p>
            <ul>
                <li>Measurement</li>
                <li>Time</li>
                <li>Currency</li>
            </ul>
            <p>Please share your feedback contact@smanne.com</p>
        </div>
    </>;
}

export default Home;