import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Collapse } from 'antd';
import DivisionProblemSettings from './DivisionProblemSettings';
import DisplaySettings from '../../components/DisplaySettings';
import DivisionProblem from '../../components/DivisionProblem';

const { Panel } = Collapse;

const Division = () => {

    const [properties, setProperties] = useState({ numberOfProblems: 20, divisor_start: 3, divisor_end: 10, dividend_start: 100, dividend_end: 999 });
    const [problems, setProblems] = useState([])
    const [settings, setSettings] = useState({ rowHeight: 120, rowWidth: 6, fontSize: 18, fontFamily: 'Poppins', printOptimized: true  })

    useEffect(() => {
        document.title = 'Division printable worksheets generator';
        generateRandomProblem()
    }, [])

    useEffect(() => {
        generateRandomProblem()
    }, [properties])

    const generateRandomProblem = () => {
        let problems = []
        for (let i = 0; i < properties.numberOfProblems; i++) {
            problems.push({
                divisor: generateRandom(properties.divisor_start, properties.divisor_end),
                dividend: generateRandom(properties.dividend_start, properties.dividend_end)
            })
        }
        setProblems(problems)
    }

    const generateRandom = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const onDisplaySettingsChange = (newSettings) => {
        setSettings(newSettings)
    }

    const onProblemSettingsChanges = (newSettings) => {
        setProperties(newSettings)
    }

    const onPrint = () => {
        window.print()
        // eslint-disable-next-line no-undef
        gtag('event', 'print', {
            'event_category' : 'print',
            'event_label' : 'division'
        });
    }

    return <>
        <Row>
            <Col span={6} xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }} className="settings">
                <Collapse defaultActiveKey={[]}>
                    <Panel header={<span style={{ fontSize: "18px", fontWeight: "700" }}>Division Problem Settings</span>} key="1">
                        <DivisionProblemSettings onChange={onProblemSettingsChanges} {...properties} />
                    </Panel>
                    <Panel header={<span style={{ fontSize: "18px", fontWeight: "700" }}>Display Settings</span>} key="2">
                        <DisplaySettings onChange={onDisplaySettingsChange} {...settings} />

                    </Panel>
                </Collapse>
                <Card className="regenerate">
                    <Button onClick={generateRandomProblem}>Regenerate</Button> <Button onClick={onPrint}>Print</Button>
                    <p className="hint">Note: You can change difficulty of problems from above settings.</p>
                </Card>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 18 }} className="worksheet-container">
                <div className={`card white worksheet-pages ${settings.printOptimized ? '' : 'display-optimized'}`}>
                    <div id="section-to-print" style={{ fontSize: settings.fontSize + "px", fontFamily: settings.fontFamily }}>
                        <Row gutter={[16, settings.rowHeight]} style={{ justifyContent: "center", marginTop: "30px" }}>
                            {problems.map((problem) => <Col span={settings.rowWidth}>
                                <DivisionProblem divisor={problem.divisor} dividend={problem.dividend} />
                            </Col>)}
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    </>;
}

export default Division;