import React, { useState, useEffect } from 'react';

const DivisionProblem = (props) => {
    return <div style={{}}>
        <div class="quotient" style={{width: "100%", height: "23px"}}>
            <span></span>
        </div>
        <div>
            <span class='divisor' style={{borderRight: "1px black solid", borderRadius: "0px 0px 10px 0px", paddingLeft: "10px", paddingRight: "10px" }}>
                {props.divisor}
            </span>
            <span class='dividend' style={{ borderTop: "1px black solid", paddingLeft: "10px", paddingRight: "10px" }}>
                {props.dividend}
            </span>
        </div>
    </div>;
}

export default DivisionProblem;

