import React, { useState, useEffect } from 'react';

const AdditionProblem = (props) => {
    const getClassName = () => {
        if (props.alignment == "horizontal") {
            return "addition-problem-horizontal"
        }
        return "addition-problem"
    }

    return <div class={getClassName()}>
            {props.rows.map((row) => <span class="addition-row">{row}</span>)}
            <span class="equal">&nbsp;=&nbsp;</span>
            <span class="answer-line">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </div>;
}

export default AdditionProblem;

