import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
const { Header, Footer, Sider } = Layout;


const HeaderBar = () => {
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    return <Header className="header">
        <div className="logo">
            <Link to="/"><img src="/logo.png" /></Link>
        </div>
        <Button className="side-menu-button" onClick={showDrawer}>
        <MenuOutlined />
        </Button>
        <Menu className="top-menu" theme="" mode="horizontal" defaultSelectedKeys={[]}>
            <Menu.Item key="1"><span>Addition</span><Link to="/addition" /></Menu.Item>
            <Menu.Item key="2"><span>Subtraction</span><Link to="/subtraction" /></Menu.Item>
            <Menu.Item key="3"><span>Multiplication</span><Link to="/multiplication" /></Menu.Item>
            <Menu.Item key="4"><span>Division</span><Link to="/division" /></Menu.Item>
        </Menu>
        <Drawer
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
        >
            <Menu className="side-menu" theme="" mode="inline" defaultSelectedKeys={[]}>
            <Menu.Item key="1"><span>Addition</span><Link to="/addition" /></Menu.Item>
            <Menu.Item key="2"><span>Subtraction</span><Link to="/subtraction" /></Menu.Item>
            <Menu.Item key="3"><span>Multiplication</span><Link to="/multiplication" /></Menu.Item>
            <Menu.Item key="4"><span>Division</span><Link to="/division" /></Menu.Item>
        </Menu>
        </Drawer>
    </Header>;
}

export default HeaderBar;