/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import {
  Switch,
  Route
} from 'react-router-dom';
import Division from '../Division';
import Addition from '../Addition';
import Subtraction from '../Subtraction';
import Multiplication from '../Multiplication';
import Home from '../Home';

/**
 * React router configuration
 */
export default () => (
    <Switch>
        <Route path="/division" component={Division} />
        <Route path="/addition" component={Addition} />
        <Route path="/subtraction" component={Subtraction} />
        <Route path="/multiplication" component={Multiplication} />
        <Route path="/" component={Home} />
    </Switch>
);