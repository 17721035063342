import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Slider, Select, Switch } from 'antd';
import { useMediaQuery } from 'react-responsive'

const { Option } = Select;


const DisplaySettings = (props) => {
    const [settings, setSettings] = useState({ rowHeight: props.rowHeight, rowWidth: props.rowWidth, fontSize: props.fontSize, fontFamily: props.fontFamily, printOptimized: props.printOptimized, alignment: props.alignment })

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    // useEffect(() => {
    //     if (isTabletOrMobile) {
    //         setSettings({ ...settings, "printOptimized": false, co })
    //     }
    // }, [isTabletOrMobile])

    useEffect(() => {
        props.onChange(settings)
    }, [settings])

    const onSliderValueChange = (key, value) => {
        if (key == "alignment" && value == "horizontal") {
                setSettings({ ...settings, [key]: value, "rowWidth": 8, "rowHeight": 100 })
        } else {
            setSettings({ ...settings, [key]: value })
        }
    }

    return <>
        {props.alignment ? <div class="display-settings-row">
            <Row>
                <Col>
                    <span class="label">Display</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Switch className="alignment-type-selector" onChange={value => {onSliderValueChange('alignment', value == true ? 'vertical':'horizontal')}} checkedChildren="Vertical" unCheckedChildren="Horizontal" defaultChecked />
                </Col>
            </Row>
        </div>:<></>}
        <div class="display-settings-row">
            <Row>
                <Col>
                    <span class="label">Font Size</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Slider
                        min={10}
                        max={25}
                        onChange={(value) => onSliderValueChange('fontSize', value)}
                        value={typeof settings.fontSize === 'number' ? settings.fontSize : 0}
                    />
                </Col>
            </Row>
        </div>
        <div class="display-settings-row">
            <Row class="label">
                <Col>
                    <span class="label">Font</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Select defaultValue={settings.fontFamily} style={{ width: "100%" }} onChange={(value) => onSliderValueChange('fontFamily', value)}>
                        <Option style={{ fontFamily: "'Cutive Mono', monospace", fontSize: settings.fontSize }} value="Cutive Mono">Cutive Mono (1234567890)</Option>
                        <Option style={{ fontFamily: "'Dancing Script', monospace", fontSize: settings.fontSize }} value="Dancing Script">Dancing Script (1234567890)</Option>
                        <Option style={{ fontFamily: "'DotGothic16', monospace", fontSize: settings.fontSize }} value="DotGothic16">DotGothic16 (1234567890)</Option>
                        <Option style={{ fontFamily: "'Indie Flower', monospace", fontSize: settings.fontSize }} value="Indie Flower">Indie Flower (1234567890)</Option>
                        <Option style={{ fontFamily: "'Poppins', sans-serif", fontSize: settings.fontSize }} value="Poppins">Poppins (1234567890)</Option>
                        <Option style={{ fontFamily: "'Roboto', sans-serif", fontSize: settings.fontSize }} value="Roboto">Roboto (1234567890)</Option>
                        <Option style={{ fontFamily: "'Sacramento', cursive", fontSize: settings.fontSize }} value="Sacramento">Sacramento (1234567890)</Option>
                        <Option style={{ fontFamily: "'Shadows Into Light', cursive", fontSize: settings.fontSize }} value="Shadows Into Light">Shadows Into Light (1234567890)</Option>
                        <Option style={{ fontFamily: "'Teko', sans-serif", fontSize: settings.fontSize }} value="Teko">Teko (1234567890)</Option>

                    </Select>
                </Col>
            </Row>
        </div>
        <div class="display-settings-row">
            <Row class="label">
                <Col>
                    <span class="label">Row height</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Slider
                        min={50}
                        max={150}
                        onChange={(value) => onSliderValueChange('rowHeight', value)}
                        value={typeof settings.rowHeight === 'number' ? settings.rowHeight : 0}
                    />
                </Col>
            </Row>
        </div>
        <div class="display-settings-row">
            <Row class="label">
                <Col>
                    <span class="label">Column width</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Slider
                        min={4}
                        max={12}
                        onChange={(value) => onSliderValueChange('rowWidth', value)}
                        value={typeof settings.rowWidth === 'number' ? settings.rowWidth : 0}
                    />
                </Col>
            </Row>
        </div>
        <div class="display-settings-row">
            <Row class="label">
                <Col>
                    <span class="label">Optimize for print</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Switch defaultChecked onChange={(value) => onSliderValueChange('printOptimized', value)} />
                </Col>
            </Row>
        </div>
    </>;
}

export default DisplaySettings;

/*
font-family: 'Cutive Mono', monospace;
font-family: 'Dancing Script', cursive;
font-family: 'DotGothic16', sans-serif;
font-family: 'Indie Flower', cursive;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Sacramento', cursive;
font-family: 'Shadows Into Light', cursive;
font-family: 'Teko', sans-serif;
*/