import React, { useState, useEffect } from 'react';
import { BrowserRouter, withRouter } from 'react-router-dom';
import {Layout} from 'antd'
import Router from '../Router';
import HeaderBar from './Header';

const { Header, Sider, Content, Footer } = Layout;


const App = () => {
    return <BrowserRouter>
        <Layout>
            <HeaderBar/>
            <Content style={{ minHeight: '85vh', margin:"10px" }}>
                <Router />
            </Content>
        </Layout>
    </BrowserRouter>
;
}
 


export default App;