import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Collapse } from 'antd';
import AdditionProblemSettings from './AdditionProblemSettings';
import DisplaySettings from '../../components/DisplaySettings';
import AdditionProblem from '../../components/AdditionComponent';

const { Panel } = Collapse;

const Addition = () => {

    const [properties, setProperties] = useState({ numberOfProblems: 20, start_number: 1, end_number: 100, number_of_rows: 2 });
    const [problems, setProblems] = useState([])
    const [settings, setSettings] = useState({ rowHeight: 100, rowWidth: 8, fontSize: 18, fontFamily: 'Poppins', printOptimized: true, alignment: 'horizontal' })

    useEffect(() => {
        document.title = 'Addition printable worksheets generator';
        generateRandomProblem()
    }, [])

    useEffect(() => {
        generateRandomProblem()
    }, [properties])

    const generateRandomProblem = () => {
        let problems = []
        for (let i = 0; i < properties.numberOfProblems; i++) {
            let rows = []
            for (let j = 0; j < properties.number_of_rows; j++) {
                rows.push(generateRandom(properties.start_number, properties.end_number))
            }
            problems.push({
                rows
            })
        }
        setProblems(problems)
    }

    const generateRandom = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const onDisplaySettingsChange = (newSettings) => {
        setSettings(newSettings)
    }

    const onProblemSettingsChanges = (newSettings) => {
        setProperties(newSettings)
    }

    const onPrint = () => {
        window.print()
        // eslint-disable-next-line no-undef
        gtag('event', 'print', {
            'event_category' : 'print',
            'event_label' : 'addition'
        });
    }

    return <>
        <Row>
            <Col span={6} xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }} className="settings">
                <Collapse defaultActiveKey={[]}>
                    <Panel header={<span style={{ fontSize: "18px", fontWeight: "700" }}>Addition Problem Settings</span>} key="1">
                        <AdditionProblemSettings onChange={onProblemSettingsChanges} {...properties} />
                    </Panel>
                    <Panel header={<span style={{ fontSize: "18px", fontWeight: "700" }}>Display Settings</span>} key="2">
                        <DisplaySettings onChange={onDisplaySettingsChange} {...settings} />

                    </Panel>
                </Collapse>
                <Card className="regenerate">
                    <Button onClick={generateRandomProblem}>Regenerate</Button> <Button onClick={onPrint}>Print</Button>
                    <p className="hint">Note: You can change difficulty of problems from above settings.</p>
                </Card>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 18 }} className="worksheet-container">
                <div className={`card white worksheet-pages ${settings.printOptimized ? '' : 'display-optimized'}`}>
                    <div id="section-to-print" style={{ fontSize: settings.fontSize + "px", fontFamily: settings.fontFamily }}>
                        <Row gutter={[16, settings.rowHeight]} style={{ justifyContent: "center", marginTop: "30px" }}>
                            {problems.map((problem) => <Col span={settings.rowWidth}>
                                <AdditionProblem {...settings} rows={problem.rows} />
                            </Col>)}
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    </>;
}

export default Addition;