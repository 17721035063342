import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Collapse } from 'antd';
import MultiplicationProblemSettings from './MultiplicationProblemSettings';
import DisplaySettings from '../../components/DisplaySettings';
import MultiplicationComponent from '../../components/MultiplicationComponent';

const { Panel } = Collapse;


const Multiplication = () => {

    const [properties, setProperties] = useState({ numberOfProblems: 20, multiplicand_start: 100, multiplicand_end: 999, multiplier_start: 1, multiplier_end: 20 });
    const [problems, setProblems] = useState([])
    const [settings, setSettings] = useState({ rowHeight: 120, rowWidth: 6, fontSize: 18, fontFamily: 'Poppins', printOptimized: true  })

    useEffect(() => {
        document.title = 'Multiplication printable worksheets generator';
        generateRandomProblem()
    }, [])

    useEffect(() => {
        generateRandomProblem()
    }, [properties])

    const generateRandomProblem = () => {
        let problems = []
        for (let i = 0; i < properties.numberOfProblems; i++) {
            problems.push({
                multiplicand: generateRandom(properties.multiplicand_start, properties.multiplicand_end),
                multiplier: generateRandom(properties.multiplier_start, properties.multiplier_end)
            })
        }
        setProblems(problems)
    }

    const generateRandom = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const onDisplaySettingsChange = (newSettings) => {
        setSettings(newSettings)
    }

    const onProblemSettingsChanges = (newSettings) => {
        setProperties(newSettings)
    }

    const onPrint = () => {
        window.print()
        // eslint-disable-next-line no-undef
        gtag('event', 'print', {
            'event_category' : 'print',
            'event_label' : 'multiplication'
        });
    }

    return <>
        <Row>
            <Col span={6} xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }} className="settings">
                <Collapse defaultActiveKey={[]}>
                    <Panel header={<span style={{ fontSize: "18px", fontWeight: "700" }}>Multiplication Problem Settings</span>} key="1">
                        <MultiplicationProblemSettings onChange={onProblemSettingsChanges} {...properties} />
                    </Panel>
                    <Panel header={<span style={{ fontSize: "18px", fontWeight: "700" }}>Display Settings</span>} key="2">
                        <DisplaySettings onChange={onDisplaySettingsChange} {...settings} />

                    </Panel>
                </Collapse>

                <Card className="regenerate">
                    <Button onClick={generateRandomProblem}>Regenerate</Button> <Button onClick={onPrint}>Print</Button>
                    <p className="hint">Note: You can change difficulty of problems from above settings.</p>
                </Card>

            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 18 }} className="worksheet-container">
                <div className={`card white worksheet-pages ${settings.printOptimized ? '' : 'display-optimized'}`}>
                    <div id="section-to-print" style={{ fontSize: settings.fontSize + "px", fontFamily: settings.fontFamily }}>
                        <Row gutter={[16, settings.rowHeight]} style={{ justifyContent: "center" }}>
                            {problems.map((problem) => <Col span={settings.rowWidth}>
                                <MultiplicationComponent multiplicand={problem.multiplicand} multiplier={problem.multiplier} />
                            </Col>)}
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    </>;
}

export default Multiplication;