import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Slider, Select, InputNumber } from 'antd';

const { Option } = Select;


const SubtractionProblemSettings = (props) => {
    const [settings, setSettings] = useState({ numberOfProblems: props.numberOfProblems, start_number: props.start_number, end_number: props.end_number, number_of_rows: props.number_of_rows})

    const onSliderValueChange = (key, value) => {
        setSettings({ ...settings, [key]: value })
    }

    useEffect(() => {
        props.onChange(settings)
    }, [settings])

    return <>
            <div class="display-settings-row">
                <Row>
                    <Col>
                        <span class="label">Number of Problems</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Slider
                            min={10}
                            max={50}
                            onChange={(value) => onSliderValueChange('numberOfProblems', value)}
                            value={typeof settings.numberOfProblems === 'number' ? settings.numberOfProblems : 0}
                        />
                    </Col>
                </Row>
            </div>
            <div class="display-settings-row">
                <Row class="label">
                    <Col>
                        <span class="label">Numbers (start - end)</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputNumber min={1} max={1000} defaultValue={settings.start_number} onChange={(value) => onSliderValueChange('start_number', value)} /> -
                    </Col> 
                    <Col>
                        <InputNumber min={1} max={100000} defaultValue={settings.end_number} onChange={(value) => onSliderValueChange('end_number', value)} />
                    </Col>
                </Row>
            </div>
            {/* <div class="display-settings-row">
                <Row class="label">
                    <Col>
                        <span class="label">Rows (start - end)</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                    <Slider
                            min={2}
                            max={5}
                            onChange={(value) => onSliderValueChange('number_of_rows', value)}
                            value={typeof settings.number_of_rows === 'number' ? settings.number_of_rows : 0}
                        />
                        </Col>
                </Row>
            </div> */}
    </>;
}

export default SubtractionProblemSettings;

/*
font-family: 'Cutive Mono', monospace;
font-family: 'Dancing Script', cursive;
font-family: 'DotGothic16', sans-serif;
font-family: 'Indie Flower', cursive;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Sacramento', cursive;
font-family: 'Shadows Into Light', cursive;
font-family: 'Teko', sans-serif;
*/