import React, { useState, useEffect } from 'react';

const MultiplicationComponent = (props) => {
    return <div class="multiplication-problem">
        <span class="multiplication-row">{props.multiplicand}</span>
        <span class="multiplication-row">{props.multiplier}</span>
    </div>;
}

export default MultiplicationComponent;

